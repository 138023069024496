import { createStore } from 'vuex'
import netlifyIdentity from 'netlify-identity-widget';
import $ from "jquery";
import axios from "axios"

export default createStore({
  state: {
    metrics: [],
    logs: [],
    users: [],
    tickets: [],
    loading: [],
    chatWith: "",
    sinceLast: 0,
    failcount: 1
  },
  mutations: {
    timerUpdate(state){
      state.sinceLast = state.sinceLast+1
      if(state.sinceLast > state.failcount && state.chatTicket != ""){
        state.sinceLast = 0
        axios.get(".netlify/functions/get-ticket?id="+state.chatWith, {
            headers: {
              Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
            }
          }
        ).then(res=>{
          if(state.tickets[state.tickets.findIndex(el => el.ref["@ref"].id == state.chatWith)].data.chat.length == res.data.data.chat.length){
            if(state.failcount < 30){
              state.failcount = state.failcount*2
            }
          }else{
            state.failcount = 1
          }
          state.tickets[state.tickets.findIndex(el => el.ref["@ref"].id == state.chatWith)].data.chat = res.data.data.chat
        })
      }
    },
    setChat(state,payload){
      state.chatWith = payload.id
    },
    askFin(state){
      let doc = [
        {
          type: "askend"
        }
      ]
      let conv = state.tickets.find(el => el.ref["@ref"].id == state.chatWith).data.chat
      let docfin = JSON.parse(JSON.stringify(conv))
      docfin.push(doc[0])
      axios.post('.netlify/functions/update-ticket', JSON.stringify({id: state.chatWith, chat: docfin}), {
        headers: {
          Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
        }
    }).then(res=>{
        state.tickets[state.tickets.findIndex(el => el.ref["@ref"].id == state.chatWith)].data.chat = res.data.data.chat
      })
    },
    sendMsg(state,msg){
      let doc = [
        {
          type: "received",
          msg: msg
        }
      ]
      let conv = state.tickets.find(el => el.ref["@ref"].id == state.chatWith).data.chat
      let docfin = JSON.parse(JSON.stringify(conv))
      docfin.push(doc[0])
      axios.post('.netlify/functions/update-ticket', JSON.stringify({id: state.chatWith, chat: docfin}), {
          headers: {
            Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
          }
      }).then(res=>{
        state.tickets[state.tickets.findIndex(el => el.ref["@ref"].id == state.chatWith)].data.chat = res.data.data.chat
        state.sinceLast = 0
      })
      
      
    },
    activateAccount(state,payload){
      state.loading[payload.key] = true
      $.ajax({
        url: ".netlify/functions/activate-account?id="+payload.id+"&date="+payload.date,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
        }
      }).then(res=>{
        state.users[payload.key].data = JSON.parse(res)
        state.loading[payload.key] = false
      })
    },
    deactivateAccount(state,payload){
      state.loading[payload.key] = true
      $.ajax({
        url: ".netlify/functions/deactivate-account?id="+payload.id+"&date="+payload.date,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
        }
      }).then(res=>{
        state.users[payload.key].data = JSON.parse(res)
        state.loading[payload.key] = false
      })
    },
    changeExp(state,payload){
      state.loading[payload.key] = true
      $.ajax({
        url: ".netlify/functions/change-expiry?id="+payload.id+"&date="+payload.date,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
        }
      }).then(res=>{
        state.users[payload.key].data = JSON.parse(res)
        state.loading[payload.key] = false
      })
    },
    getUsers(state){
      if(state.users.length == 0){
        $.ajax({
          url: ".netlify/functions/get-users",
          type: 'GET',
          headers: {
            Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
          }
        }).then(res=>{
          let result = JSON.parse(res)
          state.users = result
          result.forEach(element => {
            state.loading.push(false)
          });
        })
      }
    },
    getMetrics(state){
      $.ajax({
        url: ".netlify/functions/get-metrics",
        type: 'GET',
        headers: {
          Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`,
        }
      }).then(res=>{
        let result = JSON.parse(res)
        state.metrics = result.metrics
        state.logs = result.logs.reverse()
      }).catch(err=>{
        console.error(err)
        netlifyIdentity.logout()
      })
    },
    getTickets(state){
      axios.get(".netlify/functions/get-tickets",{
        headers:{
          Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`
        }
      }).then(res=>{
        state.tickets = res.data.data.sort((a,b)=>b.data.chat[0].ts - a.data.chat[0].ts)
      })
    }
  },
  actions: {
  },
  modules: {
  }
})
