<template>
    <br>
    <div class="animate__fadeIn animate__animated animate__faster" style="background-color: rgb(30,30,30); width: 80%; height: 80%; max-height: 80vh; overflow-y:auto; margin: auto; padding: 20px; border-radius: 10px;flex-direction: column-reverse; display: flex;" >
        
        <p v-for="log in $store.state.logs" class="text-light">
            <span>
                <br><br>
                > <b>{{ date(log.ts) }}</b>: 
                Errore per l'utente #{{ log.user }} <br>
                > {{ log.msg }} <br>
                <span class="text-light-emphasis">{{ log.ext }}</span>
            </span>
        </p>
    </div>
</template>

<script>
    export default{
        methods: {
            date: function(el){
                return new Date(el).toLocaleTimeString('it-IT',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
            },
            getuname(name){
                if(this.$store.state.users.length > 0){
                    console.log(name)
                    if(this.$store.state.users.find(e=> e.data.id == name)){
                        return"("+this.$store.state.users.find(e=> e.data.id == name).data.reference+")"
                    }
                }
            }
        }
    }
</script>
