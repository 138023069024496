<template>

  <!-- Not logged in -->
  <div v-if="!loggedIn" style="position: absolute; display: flex; justify-content: center; align-items: center; width:100vw; height: 100vh;">
      <div class="animate__animated animate__zoomIn" style="text-align: center">
        <div class="fs-1"><b>Farmadoc</b> Admin&nbsp;<i class="bi bi-chat-left-heart-fill text-secondary"></i></div>
        <button @click="login()" type="button" class="btn btn-link text-secondary">Accedi</button>
      </div>
    </div>
    <div class="row" style="width: 100vw;">
      <div class="col-3">
        <nav v-if="loggedIn" class="nav flex-column bg-secondary py-3" style="height: 100vh;">
          <div class="ticks d-flex flex-column"><span v-for="item in 60" class="flex-fill"><span :class="'tickpiece '+tickclass(item)"></span></span></div>
          <div class="p-5 fs-4"><b>Farmadoc</b> Admin&nbsp;<i class="bi bi-chat-left-heart-fill text-primary"></i></div>
          <!-- <router-link @click="ticks=1" active-class="active" to="/" class="nav-link text-black px-5 py-3"  href="#"><i class="bi bi-graph-up-arrow"></i>&nbsp;&nbsp;Statistiche</router-link> -->
          <router-link @click="ticks=2" active-class="active" to="/logs" class="nav-link text-black px-5 py-3"  href="#"><i class="bi bi-file-earmark-code"></i>&nbsp;&nbsp;Log</router-link>
          <router-link @click="ticks=3" active-class="active" to="/users" class="nav-link text-black px-5 py-3"  href="#"><i class="bi bi-person-vcard"></i>&nbsp;&nbsp;Utenti</router-link>
          <router-link @click="ticks=4" active-class="active" to="/support" class="nav-link text-black px-5 py-3"  href="#"><i class="bi bi-chat-dots"></i>&nbsp;&nbsp;Supporto</router-link>
          <a href="#" @click="logout()" class="nav-link text-black px-5 py-5" style="bottom: 0; position: absolute;"><i class="bi bi-box-arrow-right"></i>&nbsp;&nbsp;Logout</a>
        </nav>
      </div>
      <div class="col-9" style="height: 100vh; overflow-y: auto;">
        <router-view v-if="loggedIn"/>
      </div>
      
    </div>
    
</template>


<style>
*{
  box-sizing: border-box!important;
}
.nav{
  border-left: 2px solid black;
  position: relative;
}
.nav:hover > .ticks{
  width: 10%
}
.ticks{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  transition: 0.5s ease;
}
.tickpiece{
  background-color: black;
  position: absolute;
  width: 50%;
  height: 2px;
  z-index: 9;
  transition: 0.5s ease;
}
.tickpiece.big{
  background-color: black;
  position: absolute;
  width: 100%;
  height: 2px;
  z-index: 9;
  transition: 0.5s ease;
}
.nav-link{
  background-color: rgba(255,255,255,0);
  z-index: 10;
  border-right: 0px solid black;
  transition: 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
}
.nav-link.active {
  background-color: rgba(255,255,255,0.2);
  border-right: 3px solid black;
}

</style>



<script>

import netlifyIdentity from 'netlify-identity-widget';

export default{
  data(){
    return{
      ticks: 1,
      loggedIn: false
    }
  },
  created(){
    //Identity
    netlifyIdentity.init()
    const user = netlifyIdentity.currentUser();
    if(user){
      this.loggedIn = true
      this.$store.commit("getMetrics")
    }
    netlifyIdentity.on('login', user => {
      this.loggedIn = true
      this.$store.commit("getMetrics")
    });
    netlifyIdentity.on('logout', user => this.loggedIn = false);

  },
  mounted(){
      this.$store.commit("getUsers")
  },
  methods:{
    logout: function(){
      netlifyIdentity.logout()
    },
    login: function(){
      netlifyIdentity.open()
    },
    tickclass: function(item){
        if((item-this.ticks)%4==0){
          return "big"
        }else{
          return ""
        }
      },
  }
}

</script>
