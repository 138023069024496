<template>
    <br>
    <div class="container text-center">
        <div class="row animate__animated animate__faster animate__fadeInDown" v-for="(user,index) in $store.state.users" :key="user.data.id">
            <div class="col">
                <div class="card">
                    <div class="card-header text-end">
                        <div class="row">
                            <div class="col-10">
                                <h5 class="card-title text-start"><b>{{user.data.reference}}</b></h5>
                                <p class="card-text text-start">{{ user.data.clientContact }}</p>
                            </div>
                            <div class="col-2 text-end">
                                <div v-if="$store.state.loading[index]" class="spinner-border m-4"></div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body text-end">
                        
                        <p class="card-text text-start">
                            <span class="text-muted" style="opacity: 0.5">
                            Registrato il: {{ date(user.ts/1000) }}<br>
                        <span v-if="user.data.activeSub">Abbonamento scade il: {{ date(parseInt(user.data.activeSince)+2629800000) }}</span></span></p>
                        <a @click="deactivateAccount(user.ref['@ref'].id,index)" v-if="user.data.activeSub" href="#" class="btn btn-danger">Disattiva l'abbonamento</a>
                        <a @click="activateAccount(user.ref['@ref'].id,index)" v-if="!user.data.activeSub" href="#" class="btn btn-success">Attiva l'abbonamento</a>
                        <br><br>
                        <div class="input-group mb-3 disab" style="justify-content: flex-end;">

                            <span @click="extend[index]= extend[index]-86400000" class="input-group-text clk"><i class="bi bi-sunset"></i></span>
                            <span @click="extend[index] = extend[index]+86400000" class="input-group-text clk"><i class="bi bi-sunrise"></i></span>

                            <span class="input-group-text clk bg-white">{{ date(extend[index]) }}</span>

                            <span @click="extend[index]= extend[index]-2629800000" class="input-group-text clk"><i class="bi bi-calendar-minus"></i></span>
                            <span @click="extend[index]= extend[index]+2629800000" class="input-group-text clk"><i class="bi bi-calendar-plus"></i></span>
                            
                            <button @click="changeExp(user.ref['@ref'].id,extend[index],index)" class="btn btn-secondary">Modifica la data di scadenza</button>
                        </div>
                       
                    </div>
                    
                </div>
                <br>
            </div>
        </div>
    </div>
</template>

<style>

.clk{
    cursor: pointer;
}

</style>

<script>
    export default{
        data(){
            return{
                extend: []
            }
        },
        mounted(){
            this.$store.state.users.forEach(el=>{
                console.log(el.data)
                this.extend.push(el.data.activeSince+2629800000)
            })
        },
        methods:{
            date(date){
                return new Date(date).toLocaleDateString('it-IT',{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
            },
            activateAccount(id,key){
                let date = Date.now()
                this.$store.commit("activateAccount",{id,date,key})
            },
            deactivateAccount(id,key){
                let date = Date.now()
                this.$store.commit("deactivateAccount",{id,date,key})
            },
            changeExp(id,date,key){
                this.$store.commit("changeExp",{id,date,key})
            }
        }
    }
</script>
