<template>
    <div class="container text-center">
        <br><br>
        <h3>Benvenuto alla piattaforma per Amministratori</h3>
    </div>
 <!--  <div class="container text-center">
    <br>
    <div class="row animate__animated animate__faster animate__fadeInDown">
        <div class="col">
            <div class="input-group mb-3">
                <span class="input-group-text">Metrica da visualizzare</span>
                <select v-model="dataType" class="form-select">
                    <option selected value="functions">Numero di funzioni eseguite</option>
                    <option value="readOps">Operazioni di lettura</option>
                    <option value="writeOps">Operazioni di scrittura</option>
                    <option value="computeOps">Operazioni di calcolo</option>
                    <option value="queryTime">Tempo di esecuzione delle query (secondi)</option>
                </select>
            </div>
        </div>
        <div class="col">
            <div class="input-group mb-3">
                <span class="input-group-text">Periodo di riferimento</span>
                <select v-model="timeFilter" class="form-select">
                    <option selected value="86400000">Ultima giornata</option>
                    <option value="604800000">Ultima settimana</option>
                    <option value="2592000000">Ultimo mese</option>
                    <option value="31556952000">Ultimo anno</option>
                    <option value="allTime">Da sempre</option>
                </select>
            </div>
        </div>
    </div><br>
    <ul class="list-group list-group text-start border-0">
        <li class="list-group-item d-flex justify-content-between align-items-start bg-secondary border-0">
            <div class="ms-2 me-auto border-0">
            <div class="fw-bold">Costo totale</div>
            </div>
            <span class="badge bg-primary text-dark rounded-pill">${{getCost(tdata.frun, tdata.readOps, tdata.writeOps, tdata.computeOps)}}</span>
        </li>
        <li v-if="dataType=='functions'" class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
            <div class="fw-bold">Funzioni eseguite</div>
            </div>
            <span class="badge bg-secondary rounded-pill">{{tdata.frun}}</span>
        </li>
        <li v-if="dataType=='readOps'" class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
            <div class="fw-bold">Operazioni di lettura</div>
            </div>
            <span class="badge bg-secondary rounded-pill">{{tdata.readOps}}</span>
        </li>
        <li v-if="dataType=='writeOps'" class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
            <div class="fw-bold">Operazioni di scrittura</div>
            </div>
            <span class="badge bg-secondary rounded-pill">{{tdata.writeOps}}</span>
        </li>
        <li v-if="dataType=='computeOps'" class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
            <div class="fw-bold">Operazioni di calcolo</div>
            </div>
            <span class="badge bg-secondary rounded-pill">{{tdata.computeOps}}</span>
        </li>
        <li v-if="dataType=='queryTime'" class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
            <div class="fw-bold">Tempo di esecuzione delle Query</div>
            </div>
            <span class="badge bg-secondary rounded-pill">{{tdata.queryTime}}</span>
        </li>
    </ul><br>
    <area-chart class="animate__fadeIn animate__animated animate__faster" :data="data" :colors="['#33e894']"></area-chart>
    <br>
    <table class="table caption-top table-hover">
        <caption class="text-end">Metriche per utente</caption>
        <thead>
            <tr>
            <th scope="col">Utente</th>
            <th scope="col">Funzioni eseguite</th>
            <th scope="col">Operazioni di lettura</th>
            <th scope="col">Operazioni di scrittura</th>
            <th scope="col">Operazioni di calcolo</th>
            <th scope="col">Costo stimato</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="user in udata">
                <th scope="row">{{ user.uname }}</th>
                <td>{{user.frun}}</td>
                <td>{{user.readOps}}</td>
                <td>{{user.writeOps}}</td>
                <td>{{user.computeOps}}</td>
                <td>${{ getCost(user.frun,user.readOps, user.writeOps, user.computeOps)}}</td>
            </tr>
        </tbody>
    </table>
  </div> -->
</template>

<script>

export default{
    data(){
        return{
            dataType: "functions",
            timeFilter: 86400000,
            metricsObj: [],
            data: {},
            udata: {},
            tdata: {}
        }
    },
    computed: {
        metrics() {
            return this.$store.state.metrics
        }
    },
    mounted(){
        this.calculateDist()
    },
    watch: {
        metrics() {
            this.calculateDist()
        },
        dataType(){
            this.calculateDist()
        },
        timeFilter(){
            this.calculateDist()
        }
    },
    methods: {
        getCost(a,b,c,d){
            return parseFloat((0.00000045*a + 0.00000225*b + 0.00000203*c + 0.0002*d).toFixed(5))
        },
        calculateDist: function(){

            this.metrics = this.$store.state.metrics
            let data = this.metrics.filter(e=>e.ts>Date.now()-this.timeFilter || this.timeFilter == "allTime")

            let timestamps = data.map(object => object.ts);

            let n
            let intervals

            if(this.timeFilter == 86400000){
                n = 8
                let interval = this.timeFilter/n
                intervals = [
                    {
                        min: Date.now()-this.timeFilter,
                        max: Date.now()-this.timeFilter+interval
                    }
                ]
                for (let index = 0; index < n; index++) {
                    intervals.push({
                        min: (Date.now()-this.timeFilter+(interval*(index+1)))+1,
                        max: (Date.now()-this.timeFilter+(interval*(index+2))),
                    })
                }
            }
            if(this.timeFilter == 604800000){
                n = 7
                let interval = this.timeFilter/n
                intervals = [
                    {
                        min: Date.now()-this.timeFilter,
                        max: Date.now()-this.timeFilter+interval
                    }
                ]
                for (let index = 0; index < n; index++) {
                    intervals.push({
                        min: (Date.now()-this.timeFilter+(interval*(index+1)))+1,
                        max: (Date.now()-this.timeFilter+(interval*(index+2))),
                    })
                }
            }
            if(this.timeFilter == 2592000000){
                n = 30
                let interval = this.timeFilter/n
                intervals = [
                    {
                        min: Date.now()-this.timeFilter,
                        max: Date.now()-this.timeFilter+interval
                    }
                ]
                for (let index = 0; index < n; index++) {
                    intervals.push({
                        min: (Date.now()-this.timeFilter+(interval*(index+1)))+1,
                        max: (Date.now()-this.timeFilter+(interval*(index+2))),
                    })
                }
            }
            if(this.timeFilter == 31556952000){
                n = 12
                let interval = this.timeFilter/n
                intervals = [
                    {
                        min: Date.now()-this.timeFilter,
                        max: Date.now()-this.timeFilter+interval
                    }
                ]
                for (let index = 0; index < n; index++) {
                    intervals.push({
                        min: (Date.now()-this.timeFilter+(interval*(index+1)))+1,
                        max: (Date.now()-this.timeFilter+(interval*(index+2))),
                    })
                }
            }
            if(this.timeFilter == "allTime"){
                n = 30
                let interval = Math.round((Math.max(...timestamps)-Math.min(...timestamps))/n)

                intervals = [
                    {
                        min: Math.min(...timestamps),
                        max: Math.min(...timestamps)+interval
                    }
                ]
                for (let index = 0; index < n; index++) {
                    intervals.push({
                        min: (Math.min(...timestamps)+(interval*(index+1)))+1,
                        max: (Math.min(...timestamps)+(interval*(index+2))),
                    })
                }
            }


            

            let result = intervals.map(object => {return{ts: object.min, val: 0}})
            data.forEach(element => {
                
                const categoryIndex = intervals.findIndex(category => {
                    return element.ts >= category.min && element.ts <= category.max;
                });
                if(this.dataType == "functions"){
                    result[categoryIndex].val++;
                }
                if(this.dataType == "readOps"){
                    result[categoryIndex].val += element["x-byte-read-ops"];
                }
                if(this.dataType == "writeOps"){
                    result[categoryIndex].val += element["x-byte-write-ops"];
                }
                if(this.dataType == "computeOps"){
                    result[categoryIndex].val += element["x-compute-ops"];
                }
                if(this.dataType == "queryTime"){
                    result[categoryIndex].val += element["x-query-time"]/1000;
                }
                
            });

            let final = {}
            result.forEach((el,i)=>{
                if(this.timeFilter == 86400000){
                    final[new Date(el.ts).toLocaleTimeString('it-IT',{ hour: 'numeric', minute: 'numeric' })] = el.val
                }else{
                    if(this.timeFilter == 31556952000){
                        final[new Date(el.ts).toLocaleDateString('it-IT',{ month: 'short'})] = el.val
                    }
                    final[new Date(el.ts).toLocaleDateString('it-IT',{ month: 'short', day: 'numeric' })] = el.val
                }
            })

            this.data = final


            let users = []
            data.forEach(el=>{
                    if(users.filter(e => e.uid == el.uid).length == 0){
                        users.push({
                            uid: el.uid,
                            uname: el.uname,
                            frun: 1,
                            readOps: el["x-byte-read-ops"],
                            writeOps: el["x-byte-write-ops"],
                            computeOps: el["x-compute-ops"],
                            queryTime: el["x-query-time"]/1000
                        })
                    }else{
                        users[users.findIndex(e => e.uid == el.uid)].frun++
                        users[users.findIndex(e => e.uid == el.uid)].readOps += el["x-byte-read-ops"],
                        users[users.findIndex(e => e.uid == el.uid)].writeOps += el["x-byte-write-ops"],
                        users[users.findIndex(e => e.uid == el.uid)].computeOps += el["x-compute-ops"],
                        users[users.findIndex(e => e.uid == el.uid)].queryTime += el["x-query-time"]/1000
                    }
            })
            this.udata = users

            let tdata = {
                frun: 0,
                readOps: 0,
                writeOps: 0,
                computeOps: 0,
                queryTime: 0
            }
            data.forEach(el=>{
                    tdata.frun++
                    tdata.readOps += el["x-byte-read-ops"],
                    tdata.writeOps += el["x-byte-write-ops"],
                    tdata.computeOps += el["x-compute-ops"],
                    tdata.queryTime += el["x-query-time"]/1000
            })
            tdata.queryTime = parseFloat(tdata.queryTime.toFixed(5))
            this.tdata = tdata


            /* if(index == data.length-1){
                    console.log(clusters)
                    let result = {}
                    clusters.forEach((el,i)=>{
                        console.log(result);
                        console.log(i)
                        result[el.ts] = el.computeOps
                        if(i == clusters.length-1){
                            console.log(result);
                        }
                    })
                    
                } */
            

            

            /* let newData
            if(this.dataType == "functions"){
                newData = this.data.map(d => ({x: d.ts, y: 1}))
            }else{
                if(this.dataType == "functionTime" || this.dataType == "queryTime"){
                    newData = this.data.map(d => ({x: d.ts, y: d[this.dataType]/1000}))
                }else{
                    newData = this.data.map(d => ({x: d.ts, y: d[this.dataType]}))
                }
                
            }
            if(this.timeFilter!="allTime"){
                newData = newData.filter(obj => obj.x >= Date.now()-this.timeFilter);
            }
            let level
            if(this.timeFilter == 86400000){
                level = 5
            }
            if(this.timeFilter == 604800000){
                level = 7
            }
            if(this.timeFilter == 2592000000){
                level = 30
            }
            if(this.timeFilter == 31536000000 || this.timeFilter == "allTime"){
                level = 52
            }
            var binSize = Math.ceil(newData.length / level);
            var bins = [];
            for (var i = 0; i < level; i++) {
            bins.push(newData.slice(i * binSize, (i + 1) * binSize));
            }
            var binData = bins.map(function(bin) {
                var binMin = Math.min.apply(null, bin.map(function(d) { return d.x; }));
                var binMax = Math.max.apply(null, bin.map(function(d) { return d.x; }));
                return {
                    x: Math.round((binMin+binMax)/2),
                    y: bin.reduce(function(sum, d) { return sum + d.y; }, 0)
                };
            });

            this.data = binData.reduce((acc, curr) => {
                let key
                if(this.timeFilter == 86400000){
                    key = new Date(Object.values(curr)[0]).toLocaleTimeString('it-IT',{ hour: 'numeric', minute: 'numeric' });
                }else{
                    key = new Date(Object.values(curr)[0]).toLocaleDateString('it-IT',{ month: 'short', day: 'numeric' });
                }
                let value = Object.values(curr)[1];
                acc[key] = value;
                return acc;
            }, {}); */
        }
    }
}

</script>