<template>
    <br>
    <div class="container text-center animate__animated animate__fadeIn animate__fast" v-if="$store.state.tickets.length == 0">
        <p class="text-body-tertiary">Non ci sono conversazioni al momento</p>
    </div>
    <div class="container" style="height: 90%;">
        <div class="row" v-if="!conversation.open">
            <div class="col-6 mb-4" v-for="el in $store.state.tickets">
                <div class="card hvr" @click='$store.commit("setChat",{id: el.ref["@ref"].id}); conversation.open = true'>
                    <div class="card-header fs-6 text-body-tertiary">
                        <div class="row">
                            <div class="col"><i>Ticket #{{ el.ref["@ref"].id }}</i></div>
                            <div class="col-3">{{ parseDate(el.data.chat[0].ts) }}</div>
                        </div>
                       
                    </div>
                    <div class="card-body fs-5">
                        <div class="row">
                            <div class="col-8">
                                <b>{{ getuname(el.data.user) }}</b><br>
                                <span v-if="el.data.chat[el.data.chat.length-1].type == 'sent'" class="text-body-secondary text-italic">{{ el.data.chat[el.data.chat.length-1].msg }}</span>
                                <span v-if="el.data.chat[el.data.chat.length-1].type == 'received'" class="text-body-tertiary text-italic">{{ el.data.chat[el.data.chat.length-1].msg }}</span>
                            </div>
                            <div class="col-4 text-end">
                                <span v-if="el.data.open" class="badge bg-secondary">Aperto</span>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="container" style="height: 100%;" v-if="conversation.open">
            <br>
            <div class="row">
                <div class="col-2"><h4 class="text-body-secondary clk"><i @click="conversation.open = false" class="bi bi-arrow-left"></i></h4></div>
                <div class="col-10 text-end"><h6>Chat con {{ getuname(findTick().data.user) }}</h6></div>
            </div>
            
            <hr>
            <div class="p-3" style="height: 80%; box-sizing: border-box; overflow-y: auto; display:flex;align-items: flex-end; flex-direction: column-reverse;">
                <div style="display: block; position: relative; width:100%; box-sizing: border-box;">
                    <div v-for="el in findTick().data.chat">
                        <div class="text-center" style="width: 100%;" v-if="el.type == 'update'">
                            <p>{{ el.msg }}</p>
                            <hr>
                        </div>
                        <div class="text-center" style="width: 100%;" v-if="el.type == 'askend'">
                            <p>Chiusura ticket richiesta</p>
                            <hr>
                        </div>
                        <div class="text-center" style="width: 100%;" v-if="el.type == 'enddeclined'">
                            <p>La chiusura del ticket è stata rifiutata</p>
                            <hr>
                        </div>
                        <div class="text-center" style="width: 100%;" v-if="el.type == 'endapproved'">
                            <p>La chiusura del ticket è stata approvata</p>
                            <hr>
                        </div>
                        <div class="text-end mb-3 animate__animated animate__slideInUp animate__faster" v-if="el.type == 'received'">
                            <div class="card bg-body-secondary" style="max-width: 50%;border-radius: 10px 10px 0 10px; display: inline-block;">
                                <div class="card-body">
                                    {{ el.msg }}
                                </div>
                            </div>
                        </div>
                        <div class="text-start mb-3 animate__animated animate__slideInUp animate__faster" v-if="el.type == 'sent'">
                            <div class="card bg-body-secondary" style="max-width: 50%;border-radius: 0px 10px 10px 10px; display: inline-block;">
                                <div class="card-body">
                                    {{ el.msg }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3" v-if="findTick().data.open && findTick().data.chat[findTick().data.chat.length-1].type != 'endapproved'">
                <input v-on:keyup.enter='$store.commit("sendMsg",message); message = ""' v-model="message" type="text" class="form-control" placeholder="Scrivi il messaggio">
                <span @click='$store.commit("askFin")' class="input-group-text p-0"><button class="btn btn-lg btn-outline-primary" style="border-radius: 0; margin:-1px"><i class="bi bi-check-lg"></i></button></span>
                <span @click='$store.commit("sendMsg",message)' class="input-group-text p-0"><button style="border-radius: 0px 6px 6px 0px; margin: -1px" class="btn btn-lg btn-outline-primary"><i class="bi bi-send"></i></button></span>
            </div>
        </div>
    </div>

</template>

<style>
.clk{
    cursor: pointer;
}
.hvr{
    cursor: pointer;
}
.hvr:hover{
    background-color: lightgray;
}
</style>

<script>

export default{
    data(){
        return{
            conversation: {
                open: false
            },
            message: ""
        }
    },
    mounted(){
        this.$store.commit("getTickets")
        setInterval(() => {
            if(this.conversation.open){
                this.$store.commit("timerUpdate")
            }
        }, 1000);
    },
    methods:{
        parseDate(timestamp){
            const date = new Date(timestamp);
            const formattedDate = date.toLocaleString('it-IT');
            return formattedDate
        },
        findTick(){
            return this.$store.state.tickets.find(el => el.ref["@ref"].id == this.$store.state.chatWith)
        },
        getuname(name){
            if(this.$store.state.users.length > 0){
                    if(this.$store.state.users.find(e=> e.data.id == name)){
                        return this.$store.state.users.find(e=> e.data.id == name).data.reference
                    }
                }
        }
    }
}

</script>